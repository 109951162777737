/* src/frontend/LandingPage.css */
.landing-page {
  margin: 20px;
}

.dashboard-card {
  cursor: pointer;
  transition: transform 0.2s;
}

.dashboard-card:hover {
  transform: scale(1.05);
}

.error {
  color: red;
}