/* src/frontend/Schools.css*/
.schools {
    padding: 20px;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 800px;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow: auto;
    max-height: 80%;
  }
  
  .schools .MuiButton-root {
    margin-right: 10px;
  }