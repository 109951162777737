/* src/frontend/Login.css */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .login-container h2 {
    margin-bottom: 20px;
  }

  .login-logo {
    width: 150px; /* Adjust size as needed */
    margin-bottom: 20px;
  }

  .login-container form {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  .login-container input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
  }
  
  .login-container button {
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .login-container button:hover {
    background-color: #0056b3;
  }
  
  .error {
    color: red;
    margin-bottom: 10px;
  }