/* src/frontend/Languages.css */
.languages-container {
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
}

.languages-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.languages-header h4 {
    margin: 0;
}

.languages-header button {
    margin-left: 10px;
}

.table-container {
    margin-top: 20px;
}

.modal-content {
    background-color: #fff;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 800px;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow: auto;
    max-height: 80%;
}

.modal-content h6 {
    margin-top: 0;
}

.button-group {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.button-group button {
    margin-left: 10px;
}

.error-message {
    color: red;
    font-size: 16px;
    margin-top: 10px;
}