/* src/frontend/PracticeAreas.css */
.practice-areas {
    margin: 20px;
  }
  
  .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 800px;
    word-wrap: break-word;
    white-space: pre-wrap; /* Ensures text wraps and respects width constraints */
    overflow: auto; /* Ensure content stays within the box and adds scroll if needed */
    max-height: 80%; /* Ensures modal content doesn't overflow screen height */
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }