/* src/frontend/DataTable.css */
.data-table {
  margin: 20px;
}

.column-toggle {
  margin-bottom: 10px;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 800px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 24px;
  outline: none;
  max-height: 80%;
  overflow-y: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.resizer {
  display: inline-block;
  width: 10px;
  height: 100%;
  background: #ccc;
  cursor: col-resize;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  touch-action: none;
}

.table-cell {
  position: relative;
  padding-right: 10px; /* Adjust to leave space for the resizer */
}