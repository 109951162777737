/* src/frontend/HamburgerMenu.css */
.hamburger-menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  
  .menu-logo {
    width: 50px; /* Adjust size as needed */
    margin-right: 20px;
  }
  
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
  }
  
  .bm-burger-bars {
    background: #373a47;
  }
  
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  .bm-cross {
    background: #bdc3c7;
  }
  
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  .bm-morph-shape {
    fill: #373a47;
  }
  
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  .bm-item {
    display: inline-block;
    color: #d1d1d1;
    margin-bottom: 10px;
    text-decoration: none;
    transition: color 0.2s;
  }
  
  .bm-item:hover {
    color: white;
  }
  
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }